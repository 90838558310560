const RangeTile = (props) => {
  return (
    <div
      className="range-tile flex flex-1 w-2rem h-2rem text-sm border-1 border-round border-black text-0 text-center align-items-center justify-content-center"
      style={{backgroundColor: props.color}}
    >
      {props.cardPair}
    </div>
  )
}

// Underscores are there to preserve order
var rangeRows = {
  "_A": {"_AA" : "f", "_AKs": "f", "_AQs": "f", "_AJs": "f", "_ATs": "f", "_A9s": "f", "_A8s": "f", "_A7s": "f", "_A6s": "f", "_A5s": "f", "_A4s": "f", "_A3s": "f", "_A2s": "f"},
  "_K": {"_AKo": "f", "_KK" : "f", "_KQs": "f", "_KJs": "f", "_KTs": "f", "_K9s": "f", "_K8s": "f", "_K7s": "f", "_K6s": "f", "_K5s": "f", "_K4s": "f", "_K3s": "f", "_K2s": "f"},
  "_Q": {"_AQo": "f", "_KQo": "f", "_QQ" : "f", "_QJs": "f", "_QTs": "f", "_Q9s": "f", "_Q8s": "f", "_Q7s": "f", "_Q6s": "f", "_Q5s": "f", "_Q4s": "f", "_Q3s": "f", "_Q2s": "f"},
  "_J": {"_AJo": "f", "_KJo": "f", "_QJo": "f", "_JJ" : "f", "_JTs": "f", "_J9s": "f", "_J8s": "f", "_J7s": "f", "_J6s": "f", "_J5s": "f", "_J4s": "f", "_J3s": "f", "_J2s": "f"},
  "_T": {"_ATo": "f", "_KTo": "f", "_QTo": "f", "_JTo": "f", "_TT" : "f", "_T9s": "f", "_T8s": "f", "_T7s": "f", "_T6s": "f", "_T5s": "f", "_T4s": "f", "_T3s": "f", "_T2s": "f"},
  "_9": {"_A9o": "f", "_K9o": "f", "_Q9o": "f", "_J9o": "f", "_T9o": "f", "_99" : "f", "_98s": "f", "_97s": "f", "_96s": "f", "_95s": "f", "_94s": "f", "_93s": "f", "_92s": "f"},
  "_8": {"_A8o": "f", "_K8o": "f", "_Q8o": "f", "_J8o": "f", "_T8o": "f", "_98o": "f", "_88" : "f", "_87s": "f", "_86s": "f", "_85s": "f", "_84s": "f", "_83s": "f", "_82s": "f"},
  "_7": {"_A7o": "f", "_K7o": "f", "_Q7o": "f", "_J7o": "f", "_T7o": "f", "_97o": "f", "_87o": "f", "_77" : "f", "_76s": "f", "_75s": "f", "_74s": "f", "_73s": "f", "_72s": "f"},
  "_6": {"_A6o": "f", "_K6o": "f", "_Q6o": "f", "_J6o": "f", "_T6o": "f", "_96o": "f", "_86o": "f", "_76o": "f", "_66" : "f", "_65s": "f", "_64s": "f", "_63s": "f", "_62s": "f"},
  "_5": {"_A5o": "f", "_K5o": "f", "_Q5o": "f", "_J5o": "f", "_T5o": "f", "_95o": "f", "_85o": "f", "_75o": "f", "_65o": "f", "_55" : "f", "_54s": "f", "_53s": "f", "_52s": "f"},
  "_4": {"_A4o": "f", "_K4o": "f", "_Q4o": "f", "_J4o": "f", "_T4o": "f", "_94o": "f", "_84o": "f", "_74o": "f", "_64o": "f", "_54o": "f", "_44" : "f", "_43s": "f", "_42s": "f"},
  "_3": {"_A3o": "f", "_K3o": "f", "_Q3o": "f", "_J3o": "f", "_T3o": "f", "_93o": "f", "_83o": "f", "_73o": "f", "_63o": "f", "_53o": "f", "_43o": "f", "_33" : "f", "_32s": "f"},
  "_2": {"_A2o": "f", "_K2o": "f", "_Q2o": "f", "_J2o": "f", "_T2o": "f", "_92o": "f", "_82o": "f", "_72o": "f", "_62o": "f", "_52o": "f", "_42o": "f", "_32o": "f", "_22" : "f"},
}
const rangeRowsBlank = JSON.stringify(rangeRows)

var cardToValueMap = {
  "A": 14, "K": 13, "Q": 12, "J": 11, "T": 10, "9": 9,
  "8": 8, "7": 7, "6": 6, "5": 5, "4": 4, "3": 3, "2": 2
}
var valueToCardMap = {
  14: "A", 13: "K", 12: "Q", 11: "J", 10: "T", 9: "9",
  8: "8", 7: "7", 6: "6", 5: "5", 4: "4", 3: "3", 2: "2"
}

const setAction = (row, pair, action) => {
  // console.log("Setting action for: " + row + "-" + pair)
  rangeRows["_" + row]["_" + pair] = action
  return 0
}

const setSliceCardRange = (start1, start2, end1, end2, suit, action) => {
  var start1Val = cardToValueMap[start1]
  var start2Val = cardToValueMap[start2]
  var end1Val = cardToValueMap[end1]
  var end2Val = cardToValueMap[end2]
  // console.log("Set item with + (" + start1Val, "," + start2Val + ")")
  // console.log("Set item with + (" + end1Val, "," + end2Val + ")")
  if (suit === "o") {
    // console.log("Offsuit cardPair, starting at " + start2Val)
    while (start2Val < end2Val) {
      start2Val++
      if (start2Val >= start1Val) {
        continue
      }
      setAction(valueToCardMap[start2Val], start1 + valueToCardMap[start2Val] + suit, action)
    }
  } else if (suit === "s") {
    // console.log("Suited cardPair, starting at " + start2Val)
    while (start2Val < end2Val) {
      start2Val++
      if (start2Val >= start1Val) {
        continue
      }
      setAction(start1, start1 + valueToCardMap[start2Val] + suit, action)
    }
  } else {
    // Pairs
    // console.log("Paired cardPair, starting at " + start1Val)
    // console.log("Will end loop at: " + valueToCardMap[end1Val])
    while (start1Val < end1Val) {
      start1Val++
      setAction(valueToCardMap[start1Val], valueToCardMap[start1Val] + valueToCardMap[start1Val], action)
    }
  }
}

const rangeDefs = {
  "rfi": {
    "lj": {
      "r": "66+,A3s+,K8s+,Q9s+,J9s+,T9s,ATo+,KJo+,QJo",
    },
    "hj": {
      "r": "55+,A2s+,K6s+,Q8s+,J9s+,T9s,98s,87s,76s,ATo+,KTo+,QTo+",
    },
    "co": {
      "r": "33+,A2s+,K3s+,Q6s+,J8s+,T7s+,97s+,87s,76s,A8o+,KTo+,QTo+,JTo",
    },
    "btn": {
      "r": "22+,A2s+,K2s+,Q3s+,J4s+,T6s+,96s+,85s+,75s+,64s+,53s+,A4o+,K8o+,Q9o+,J9o+,T8o+,98o",
    },
    "sb": {
      // "r": "33-22,KK-JJ,AKs,ATs-A7s,A5s,KJs,KTs,K8s,K5s,K3s,K2s,QTs+,Q5s-Q2s,JTs,J7s-J4s,T6s,T5s,96s,64s+,53s+,AQo,AJo,A8o-A6o,A4o,KJo,K9o-K7o,Q9o,J9o,T8o,98o",
      // "l": "AA,TT-44,AQs,AJs,A6s,A5s-A2s,KQs,K9s,K7s,K6s,K4s,Q9s-Q6s,J9s,J8s,J3s,J2s,T8s,T7s,T4s,T3s,97s+,95s,94s,74s+,63s,43s,AKo,ATo,A9o,A5o,A3o,A2o,KQo,KTo,K6o-K4o,QTo+,Q8o-Q5o,JTo,J8o,J7o,T9o,T7o,97o,86o+,76o",
      "r4": "KK-JJ,AKs,AJo+,KJo",
      "rc": "33-22,ATs-A7s,A5s,KJs,KTs,K8s,K5s,QTs+,JTs,T9s,65s,54s,K9o,Q9o,J9o,98o",
      "rf": "K3s,K2s,Q5s-Q2s,J7s-J4s,T6s,T5s,96s,64s,53s,A8o-A6o,A4o,K8o,K7o,T8o",
      "lr": "AA,TT-88,AQs,AJs,KQs,K9s,Q9s,J9s,98s,87s,AKo",
      "lc": "77-44,A6s,A4s-A2s,K7s,K6s,K4s,Q8s-Q6s,J8s,T8s,T7s,97s,86s,85s,76s,75s,ATo,A9o,A5o,KQo,KTo,QTo+,JTo,T9o",
      "lf": "J3s,J2s,T4s,T3s,95s,94s,84s,74s,63s,43s,A3o,A2o,K6o-K4o,Q8o-Q5o,J8o,J7o,T7o,97o,86o+,76o",
    },
  },
  "facing_rfi": {
    "hj": {
      "lj": {
        "3": "99+,ATs+,A5s,KTs+,QJs,AQo+,KQo",
      },
    },
    "co": {
      "lj": {
        "3": "88+,ATs+,A5s,KTs+,QJs,AQo+,KQo",
      },
      "hj": {
        "3": "88+,A9s+,A5s,A4s,KTs+,QJs,AJo+,KQo",
      },
    },
    "btn": {
      "lj": {
        "3": "JJ+,AQs+,A9s,A8s,A4s,A3s,K9s,QJs,T9s,AKo,AJo,KQo",
        "c": "TT-55,AJs,ATs,A5s,KTs+,QTs,JTs,76s,65s,54s,AQo",
      },
      "hj": {
        "3": "JJ+,66,AQs+,A9s-A7s,A4s,A3s,KTs-K8s,QTs,Q9s,T9s,AKo,AJo,KQo",
        "c": "TT-77,55,44,AJs,ATs,A5s,KJs+,QJs,JTs,98s,87s,AQo",
      },
      "co": {
        "3": "TT+,55,AQs+,A8s-A6s,A4s,A3s,KQs,K9s,QJs,Q9s,J9s+,AKo,AJo,ATo,KJo+,QJo",
        "c": "99-66,AJs-A9s,A5s,KJs,KTs,QTs,T9s,98s,AQo",
      },
    },
    "sb": {
      "lj": {
        "3": "99+,ATs+,A5s,KTs+,QJs,AQo+",
      },
      "hj": {
        "3": "77+,ATs+,A5s,KTs+,QTs+,JTs,AQo+",
      },
      "co": {
        "3": "66+,A9s+,A5s,KTs+,QTs+,J9s+,T9s,AQo+,KQo",
      },
      "btn": {
        "3": "55+,A7s+,A5s,A4s,K9s+,Q9s+,J9s+,T8s+,AJo+,KJo+",
      },
    },
    "bb": {
      "lj": {
        "3": "JJ+,AQs+,A5s,A4s,KJs+,QJs,JTs,65s,54s,AKo",
        "c": "TT-22,AJs-A6s,A3s,A2s,KTs-K2s,QTs-Q5s,J9s,J8s,T7s+,96s+,85s+,74s+,64s,63s,53s,43s,32s,AQo-ATo,KJo+,QJo,JTo",
      },
      "hj": {
        "3": "TT+,AQs+,A9s,A5s,A4s,KTs+,K5s,QTs+,JTs,65s,54s,AKo",
        "c": "99-22,AJs-ATs,A8s-A6s,A3s,A2s,K9s-K6s,K4s-K2s,Q9s-Q5s,J9s-J7s,T7s+,96s+,85s+,74s+,64s,63s,53s,43s,AQo-A9o,KTo+,QTo+,JTo",
      },
      "co": {
        "3": "99+,AJs+,A9s,A5s,A4s,KTs+,Q9s+,J9s+,T9s,65s,54s,AQo+",
        "c": "88-22,ATs,A8s-A6s,A3s,A2s,K9s-K2s,Q8s-Q3s,J8s-J6s,T8s,T7s,96s+,85s+,74s+,64s,63s,53s,52s,43s,AJo-A8o,A5o,KTo+,QTo+,JTo,T9o",
      },
      "btn": {
        "3": "88+,ATs+,A6s-A4s,K9s+,Q9s+,J8s+,T8s+,97s+,87s,76s,65s,54s,AQo+,KQo",
        "c": "77-22,A9s-A7s,A3s,A2s,K8s-K2s,Q8s-Q2s,J7s-J2s,T7s-T2s,96s-94s,86s-84s,75s-73s,64s-62s,53s,52s,42s+,32s,AJo-A3o,KJo-K6o,Q8o+,J8o+,T8o+,98o,87o,76o,65o,54o",
      },
      "sb": {
        "3": "88+,ATs+,A5s,A4s,KTs+,QJs,J5s,T5s,95s,87s,76s,65s,54s,AQo+,A6o,K6o,K5o,Q6o,J8o,J7o,T7o",
        "h": "77-22,A9s-A6s,A3s,A2s,K9s-K2s,QTs-Q2s,J6s+,J4s-J2s,T6s+,T4s-T2s,96s+,94s-92s,86s-84s,75s-73s,64s-62s,53s,52s,42s+,32s,AJo-A7o,A5o-A2o,K7o+,Q7o+,J9o+,T8o+,97o+,86o+,76o,65o,54o",
      },
    },
  },
  "facing_limp": {
    "bb": {
      "sb": {
        "r": "33+,A8s+,A5s-A2s,K9s+,K6s,K5s,Q9s+,J8s+,J2s,T8s+,T4s-T2s,97s+,94s-92s,86s+,84s,73s+,63s+,54s,32s,ATo+,A5o,KJo+,K5o,K4o,Q6o-Q4o,JTo,J7o-J5o,T9o,T6o,T5o,96o,95o,85o,75o,74o",
        "c": "22,A7s,A6s,A2s,K8s,K7s,K4s-K2s,Q8s-Q2s,J7s-J3s,T7s-T5s,96s,95s,85s,83s,82s,72s,62s,53s,52s,43s,42s,A9o-A6o,A4o-A2o,KTo-K6o,K3o,K2o,Q7o+,Q3o,Q2o,J9o,J8o,J4o-J2o,T8o,T7o,T4o-T2o,97o+,94o-92o,86o+,84o-82o,76o,73o,72o,62o+,52o+,42o+,32o",
      }
    }
  }
}

const generateRangeItems = (stage, position, opponent) => {
  var rangeStrs = []
  if (stage === "rfi") {
    console.log("Generating range for: " + stage + " as " + position)
    if (position in rangeDefs[stage]) {
      rangeStrs = rangeDefs[stage][position]
    }
  } else if ((stage === "facing_rfi") || (stage === "facing_limp")) {
    console.log("Generating range for: " + stage + " as " + position + " vs " + opponent)
    if (position in rangeDefs[stage]) {
      if (opponent in rangeDefs[stage][position]) {
        rangeStrs =rangeDefs[stage][position][opponent]
      }
    }
  }
  rangeRows = JSON.parse(rangeRowsBlank)
  for (let rangeStr in rangeStrs) {
    console.log("Parsing range str (" + rangeStr + "): " + rangeStrs[rangeStr])
    const action = rangeStr
    const rangeSets = rangeStrs[rangeStr].split(",")
    rangeSets.map((set) => {
      // console.log(set)
      var suit = ""
      var card1 = set[0]
      var card2 = set[1]
      if (set.includes("-")) {
        // console.log("======Parsing a card range======")
        const start = set.split("-")[1]
        card1 = start[0]
        card2 = start[1]
        const end = set.split("-")[0]
        // console.log("Start: " + start + " End: " + end)
        if (start.includes("o")) {
          suit = "o"
        } else if (start.includes("s")) {
          suit = "s"
        }
        setSliceCardRange(start[0], start[1], end[0], end[1], suit, action)
      } else if(set.includes("+")) {
        // console.log("======Parsing a plus range=====")
        if (set.includes("o")) {
          suit = "o"
        } else if (set.includes("s")) {
          suit = "s"
        }
        // console.log("Start: " + set)
        setSliceCardRange(set[0], set[1], "A", "A", suit, action)
      }

      // Get the row index based on suitedness
      var ridx = card1
      if (set.includes("o")) {
        ridx = card2
        suit = "o"
      } else if (set.includes("s")) {
        suit = "s"
      }

      // console.log("Marking base card: " + card1 + card2 + suit)
      // console.log("Row index: " + ridx)

      setAction(ridx, card1 + card2 + suit, action)

      return {}
    })
  }
  return rangeRows
}

const actionMap = {
  "r": "red",
  "3": "red",
  "4": "red",
  "l": "blue",
  "c": "blue",
  "h": "blue",
  "r4": "red",
  "rc": "blue",
  "rf": "green",
  "lr": "black",
  "lc": "orange",
  "lf": "pink",
  "f": "white",
}

const RangeTable = (props) => {
  const rangeTableRows = generateRangeItems(props.stage, props.position, props.opponent)
  // console.log(rangeTableRows)
  return (
    <>
      <div className="flex">
        <table>
          <tbody>
            {
              Object.entries(rangeTableRows).map(([index, row]) => {
                // console.log(row + " " + index)
                return (
                  <tr key={index}>
                    {
                      Object.entries(row).map(([tile]) => {
                        // console.log("Row: " + index + " " + tile)
                        const cardPair = tile.slice(1)
                        const color = actionMap[row[tile]]
                        return (
                          <td key={tile}>
                            <RangeTile key={tile} cardPair={cardPair} color={color} />
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default RangeTable