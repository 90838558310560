import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { useState } from 'react';
import RangeTable from '../components/RangeTable';

const positionMap = {
  0: {
    0: "sb",
    1: "bb",
  },
  1: {
    0: "btn",
    1: "sb",
    2: "bb",
    3: "utg",
  },
  2: {
    0: "btn",
    1: "sb",
    2: "bb",
    3: "lj",
    4: "hj",
    5: "co",
  },
  3: {
    0: "btn",
    1: "sb",
    2: "bb",
    3: "utg",
    4: "utg+1",
    5: "utg+2",
    6: "lj",
    7: "hj",
    8: "co",
  },
}
const tableSizeMap = {
  0: 2,
  1: 4,
  2: 6,
  3: 9,
}
const tableSizeStrMap = {
  0: "Heads-Up",
  1: "4-Max",
  2: "6-Max",
  3: "Full-Ring",
}
const stackSizeMap = {
  0: "15BB",
  1: "25BB",
  2: "40BB",
  3: "75BB",
  4: "100BB",
}
const gameType = {
  false: "Cash Game",
  true: "Tournament"
}
const stageMap = {
  0: "rfi",
  1: "facing_rfi",
  2: "facing_limp",
}

const renderOpponentButtons = (stage, opponent, prevOpponent, nextOpponent) => {
  if (stage !== 0) {
    return (
      <div>
        <div></div>
        <Button 
          className="m-1" label="Prev" 
          icon="pi pi-angle-double-left"
          onClick={prevOpponent}
        />
        <span>Opponent: {opponent}</span>
        <Button 
          className="m-1" label="Next" 
          icon="pi pi-angle-double-right"
          iconPos="right"
          onClick={nextOpponent}
        />
      </div>
    )
  }
  return (<></>)
}

const renderStackSizeButtons = (isTournament, stackSize, prevStackSize, nextStackSize) => {
  if (isTournament) {
    return (
      <div>
        <div></div>
        <Button 
          className="m-1" label="Prev" 
          icon="pi pi-angle-double-left"
          onClick={prevStackSize}
        />
        <span>StackSize: {stackSizeMap[stackSize]}</span>
        <Button 
          className="m-1" label="Next" 
          icon="pi pi-angle-double-right"
          iconPos="right"
          onClick={nextStackSize}
        />
      </div>
    )
  }
}

const Ranges = () => {
  const [stage, setStage] = useState(0);
  const prevStage = () => setStage((stage - 1) % 3)
  const nextStage = () => setStage((stage + 1) % 3)
  const [tableSize, setTableSize] = useState(2);
  const prevTableSize = () => setTableSize((tableSize - 1) % 4)
  const nextTableSize = () => setTableSize((tableSize + 1) % 4)

  const playerCount = tableSizeMap[tableSize]
  const [position, setPosition] = useState(0);
  const prevPosition = () => setPosition((position - 1 + playerCount) % playerCount)
  const nextPosition = () => setPosition((position + 1 + playerCount) % playerCount)
  const [opponent, setOpponent] = useState(1);
  const prevOpponent = () => setOpponent((opponent - 1 + playerCount) % playerCount)
  const nextOpponent = () => setOpponent((opponent + 1 + playerCount) % playerCount)
  const [stackSize, setStackSize] = useState(4);
  const prevStackSize = () => setStackSize((stackSize - 1) % 5)
  const nextStackSize = () => setStackSize((stackSize + 1) % 5)
  const [isTournament, setIsTournament] = useState(false);
  const toggleIsTournament = () => setIsTournament(!isTournament)
  return (
    <Card
      className="dash flex-initial border-1 text-center"
      title="Range Charts"
    >
      <div><span>{tableSizeStrMap[tableSize]} {stackSizeMap[stackSize]} {gameType[isTournament]}</span></div>
      <RangeTable 
        stage={stageMap[stage]}
        tableSize={tableSizeMap[tableSize]}
        stackSize={stackSizeMap[stackSize]}
        position={positionMap[tableSize][position]}
        opponent={positionMap[tableSize][opponent]}
        isTournament={isTournament}
      />
      <Button 
        className="m-1" label="Prev" 
        icon="pi pi-angle-double-left" 
        onClick={prevStage}
      />
      <span>Stage: {stageMap[stage]}</span>
      <Button
        className="m-1" label="Next"
        icon="pi pi-angle-double-right"
        iconPos="right"
        onClick={nextStage}
      />
      <div></div>
      <Button 
        className="m-1" label="Prev" 
        icon="pi pi-angle-double-left" 
        onClick={prevPosition}
      />
      <span>Position: {positionMap[tableSize][position]}</span>
      <Button
        className="m-1" label="Next"
        icon="pi pi-angle-double-right"
        iconPos="right"
        onClick={nextPosition}
      />
      {renderOpponentButtons(stage, positionMap[tableSize][opponent], prevOpponent, nextOpponent)}
      <div></div>
      <Button 
        className="m-1" label="Prev" 
        icon="pi pi-angle-double-left"
        onClick={prevTableSize}
      />
      <span>TableSize: {tableSizeMap[tableSize]}</span>
      <Button 
        className="m-1" label="Next" 
        icon="pi pi-angle-double-right"
        iconPos="right"
        onClick={nextTableSize}
      />
      <div></div>
      <Button 
        className="m-1" label="Toggle Tournament" 
        onClick={toggleIsTournament}
      />
      {renderStackSizeButtons(isTournament, stackSize, prevStackSize, nextStackSize)}
    </Card>
  )
}

export default Ranges