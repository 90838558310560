import UploadHHFileForm from "../forms/UploadHHFileForm";
import { Card } from "primereact/card";

const Upload = () => {

  return (
    <Card
      className="dash flex-initial m-2 border-1 text-center"
      title="Upload Hand History Files"
    >
      <UploadHHFileForm />
    </Card>
  )
}

export default Upload