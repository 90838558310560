import React, { useRef } from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Menubar } from 'primereact/menubar';
import { Toast } from 'primereact/toast';

const Navbar = (props) => {
  const account_menu = useRef(null);
  const navigate = useNavigate();
  const toast = useRef(null);
  const main_menu_items = [
    {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        command:() => { navigate('/') },
    },
    {
        label: 'Tools',
        items: [
            { label: 'Statistics', icon: 'pi pi-fw pi-chart-line', command:() => { navigate('/stats') } },
            { label: 'Hand Reviewer', icon: 'pi pi-fw pi-video', command:() => { navigate('/hands') } },
            { label: 'Leak Finder', icon: 'pi pi-fw pi-key', command:() => { navigate('/leaks') } },
            { label: 'Range Charts', icon: 'pi pi-fw pi-table', command:() => { navigate('/ranges') } },
            { label: 'Bankroll Tool', icon: 'pi pi-fw pi-money-bill', command:() => { navigate('/bankroll') } },
        ],
    },
    {
        label: 'Manage',
        items: [
            { 
                label: 'Files',
                icon: 'pi pi-fw pi-folder-open',
                items: [
                    { label: 'Upload HH', icon: 'pi pi-fw pi-cloud-upload', command:() => { navigate('/upload') }},
                    { label: 'Export OHH JSON', icon: 'pi pi-fw pi-cloud-download'},
                ]
            },
            { separator: true },
            { label: 'Settings', icon: 'pi pi-fw pi-cog' },
        ]
    }
  ]
  const account_menu_items = [
    {
        label: 'Account Settings',
        icon: 'pi pi-fw pi-cog',
        command:(e) => { window.location.hash = '/account' },
    },
    {
        label: 'Logout',
        icon: 'pi pi-fw pi-sign-out',
        command:(e) => { props.signOut() },
    },
  ]
  const end = (
    <div className="card">
      <Menu model={account_menu_items} popup ref={account_menu} id="account_menu" />
      <Button label={props.user} icon="pi pi-user" onClick={(event) => account_menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
    </div>
  )
  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <Menubar model={main_menu_items} end={end} />
      </div>
    </div>
  )
}

export default Navbar;