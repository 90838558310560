import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './assets/_theme.css';
import './assets/index.css';
import Layout from './pages/Layout';
import Dashboard from './pages/Dashboard';
import Hands from './pages/Hands';
import NoPage from './pages/NoPage';
import Upload from './pages/Upload';
import Ranges from './pages/Ranges';

Amplify.configure({
  Auth: {
    identityPoolId: "us-west-1:771821ac-ce0a-404e-9e48-e500c5bec405",
    region: "us-west-1",
    identityPoolRegion: "us-west-1",
    userPoolId: "us-west-1_SI62FNKcG",
    userPoolWebClientId: "4t755ugkknblhdlmfdg4nr3317",
    mandatorySignIn: true,
  },
  "aws_cognito_username_attributes": [],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
      "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OPTIONAL",
  "aws_cognito_mfa_types": [
      "TOTP"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 12,
      "passwordPolicyCharacters": [
          "REQUIRES_LOWERCASE",
          "REQUIRES_UPPERCASE",
          "REQUIRES_NUMBERS",
          "REQUIRES_SYMBOLS"
      ]
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
  ],
  "aws_dynamodb_all_tables_region": "us-west-1",
  "aws_dynamodb_table_schemas": [
      {
          "tableName": "pokertool_hands",
          "region": "us-west-1"
      }
  ]
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Authenticator
    className="auth"
    hideSignUp={true}
  >
    {({ signOut, user }) => (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout signOut={signOut} user={user}/>}>
            <Route index element={<Dashboard />} />
            <Route path="hands" element={<Hands />} />
            <Route path="ranges" element={<Ranges />} />
            <Route path="upload" element={<Upload />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    )}
  </Authenticator>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
