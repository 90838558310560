import { useRef } from 'react';
import { uploadHHFile } from '../api/sqs';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';

const UploadHHFileForm = () => {
  const uploadFileRef = useRef([]);

  const itemTemplate = (file, props) => {
    if (!file.name.startsWith("HH")) {
      console.log("Not a valid HH file")
      return false
    }
    const sessionTime = file.name.split(" - ")[0].substring(2)
    const dt = new Date(
      sessionTime.slice(0,4),
      sessionTime.slice(4,6)-1,
      sessionTime.slice(6,8),
      sessionTime.slice(9,11),
      sessionTime.slice(11,13),
      sessionTime.slice(14,16),
    ).toLocaleString('en-US')
    const filename = file.name.substring(20)
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center">
          <div className="p-upload-filename">
            <span className="flex flex-column text-left ml-3">
              {filename}
              <small>{dt}</small>
            </span>
          </div>
        </div>
        <Tag value={props.formatSize} className="px-3 py-2" />
        <Button 
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    )
  }

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i 
          className="pi pi-file mt-3 p-5"
          style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}>
        </i>
        <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="my-5">Drag and Drop Files Here</span>
      </div>
    )
  }
  const onTemplateSelect = (e) => {
    for (var i=0; i<e.files.length; i++) {
      uploadFileRef.current.push(e.files[i])
    }
  }
  const onTemplateClear = () => {
    uploadFileRef.current = []
  }
  const onTemplateRemove = (file, callback) => {
    uploadFileRef.current = uploadFileRef.current.filter(item => item.name !== file.name)
    callback()
  }
  const handleUpload = () => {
    var files = uploadFileRef.current
    console.log("Files: " + files)
    for (var i=0; i<files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.addEventListener('loadend', function(e) {
        const blob = new Blob([reader.result], {type: file.type})
        blob.text()
          .then((text) => {
            const hands = text.trim().split("\r\n\r\n");
            console.log("File has " + hands.length + " hands");

            const MAX_FILE_SIZE = 224 * 1024;
            var handGroup = [];
            var handGroups = [[]];
            var handGroupSize = 0;
            var gIdx = 0;
            for (var hIdx = 0; hIdx < hands.length; hIdx++) {
              if (handGroupSize + hands[hIdx].length > MAX_FILE_SIZE) {
                console.log("Ending group, " + handGroup.length + " hands");
                handGroups[gIdx] = handGroup;
                handGroup = [];
                handGroupSize = 0;
                gIdx++;
                hIdx--; // don't increment hands, this one wasn't added yet
              } else {
                handGroupSize += hands[hIdx].length;
                handGroup.push(hands[hIdx]);
              }
            }
            handGroups[gIdx] = handGroup;
            console.log("Total of " + (gIdx + 1) + " groups");

            console.log("Sending hands to SQS...")
            for (let gIdx = 0; gIdx < handGroups.length; gIdx++) {
              console.log("Group has " + handGroups[gIdx].length + " hands");
                uploadHHFile({
                  filenumber: gIdx,
                  filename: file.name,
                  hands: handGroups[gIdx]
                })
                .then(resp => {
                  const status = resp?.$metadata.httpStatusCode
                  if (status === 200) {
                    console.log("Uploaded hand successfully");
                  } else {
                    console.error("Failed to upload HH File: " + status);
                  }
                })
                .catch(err => {
                  console.error("Caught error: " + err);
                })
            }
          })
      });
      reader.readAsArrayBuffer(file);

    }
    return false;
  }
  return (
    <div>
      <FileUpload multiple
        name="demo[]"
        url="/uploads"
        accept=".txt"
        onSelect={onTemplateSelect}
        onError={onTemplateClear} onClear={onTemplateClear}
        customUpload uploadHandler={handleUpload}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
      />
    </div>
  )
}

export default UploadHHFileForm