import { useOutletContext } from "react-router";
import { Card } from 'primereact/card';

const Dashboard = () => {
  const user = useOutletContext();
  return (
    <Card className="dash flex-1 w-8rem h-8rem m-2 border-1">
      <p>Hello, {user}</p>
    </Card>
  )
}

export default Dashboard