import { Outlet } from "react-router-dom";
import Navbar from '../components/Navbar';

const Layout = (props) => {
  return (
    <>
      <Navbar signOut={props.signOut} user={props.user.username}/>
      <div className="flex card-container justify-content-center">
        <Outlet context={props.user.username}/>
      </div>
    </>
  )
}

export default Layout;