import { SendMessageCommand, SQSClient } from '@aws-sdk/client-sqs';
import { Auth } from "aws-amplify";
const REGION = "us-west-1"

export const uploadHHFile = async (data) => {
  const userinfo = await Auth.currentUserInfo()
  const credentials = await Auth.currentCredentials()
  const sqsClient = new SQSClient({ 
    credentials: Auth.essentialCredentials(credentials),
    region: REGION
  });

  const params = {
    QueueUrl: 'https://sqs.us-west-1.amazonaws.com/056345045143/pat_hh-upload-queue',
    MessageBody: JSON.stringify(data.hands.map(function(v) {
      return v.trim();
    })), // JSON list of hand content strings
    MessageAttributes: {
      filenumber: {
        DataType: 'String',
        StringValue: data.filenumber
      },
      filename: {
        DataType: 'String',
        StringValue: data.filename
      },
      username: {
        DataType: 'String',
        StringValue: userinfo.username
      }
    }
  }

  try {
    const data = await sqsClient.send(new SendMessageCommand(params));
    return data;
  } catch (err) {
    console.error("Error putting hand history file: " + err);
    return err
  };
}